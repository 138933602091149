import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Tile from "../../components/Tile.js";
import withNeon, { fx } from "react-neon";
const effect = new fx.Snow({ size: 10 });
const Snow = withNeon(Tile, effect);
export const frontmatter = {
  title: 'Snow',
  author: 'Chris Neale',
  draft: false,
  date: '2018-11-29T23:46:37.121Z',
  tags: ['effect']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>

<MDXTag name="h3" components={components}>{`Effects / Snow`}</MDXTag>
<MDXTag name="p" components={components}>{`Add a snow fall effect to a component.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Options`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Option`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`What it does`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`size`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Distance to draw outside of the component in pixels`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Example`}</MDXTag>
<Snow />
<MDXTag name="h3" components={components}>{`Example Code`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript"><span className="token keyword">import</span> Tile <span className="token keyword">from</span> <span className="token string">"../../components/Tile.js"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">import</span> withNeon<span className="token punctuation">,</span> <span className="token punctuation">{"{"}</span> fx <span className="token punctuation">{"}"}</span> <span className="token keyword">from</span> <span className="token string">"react-neon"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">const</span> effect <span className="token operator">=</span> <span className="token keyword">new</span> <span className="token class-name">fx<span className="token punctuation">.</span>Snow</span><span className="token punctuation">(</span><span className="token punctuation">{"{"}</span> size<span className="token punctuation">:</span> <span className="token number">10</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">const</span> Snow <span className="token operator">=</span> <span className="token function">withNeon</span><span className="token punctuation">(</span>Tile<span className="token punctuation">,</span> effect<span className="token punctuation">)</span><span className="token punctuation">;</span></code></pre></div>
</MDXTag>

export const _frontmatter = {};

  